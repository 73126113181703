import React, { useState } from 'react'
import Api from '../../app/services/Api'
import useNotifications from '../../hooks/useNotifications'
import LoadingHolder from '../../components/LoadingHolder'

function DiscoveryLogin({ children }) {
  const { notifyError } = useNotifications()
  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [lookupcode, setlookupcode] = useState('')

  const load = () => {
    setLoading(true)
    setError(false)
    const api = new Api()
    api.get(`public/discovery/locations/${lookupcode}`)
    .then(({ data: { body, statusCode } }) => {
      setLoading(false)
      if (statusCode === 401)
        notifyError('Something went wrong', 'lookupcode wrong')
      else
        setData(body)
    })
  }

  return (
    <div>
      <form className="mt-8" action="#" method="POST" onSubmit={event => {
        event.preventDefault()
        load()
      }}>
        <div className="rounded-md shadow-sm">
          <div>
            <input
              aria-label="Lookup Code"
              type="text"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Company Lookup Code"
              value={lookupcode}
              onChange={event => setlookupcode(event.target.value)} />
          </div>
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
            disabled={loading}>
            <LoadingHolder loading={loading} label="Next" />
          </button>
        </div>
      </form>

      {children({ error, data, loading, load })}
    </div>
  )
}

export default DiscoveryLogin
