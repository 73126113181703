import { createSlice } from '@reduxjs/toolkit'

export const AdminUsersSlice = createSlice({
  name: 'admin',
  initialState: {
    users: [],
  },
  reducers: {
    addUser: (state, { payload }) => {
      state.users.push(payload)
    },
    updateUser: (state, { payload }) => {
      const newState = state
      const itemIndex = state.users.findIndex(({ id }) => id === payload.id)

      newState.users[itemIndex] = payload
    },
    removeUsers: (state, { payload }) => {
      const newState = state
      newState.users = state.users.filter((item, index) => index !== payload)
    },
    resetUsers: state => {
      const newState = state
      newState.users = []
    },
    fillUsers: (state, { payload }) => {
      const newState = state
      newState.users = payload
    },
  },
})

export const { addUser, removeUsers, resetUsers, fillUsers, updateUser } = AdminUsersSlice.actions

export default AdminUsersSlice.reducer
