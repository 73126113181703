import moment from 'moment'

export const TimestampParser = value => new Date(value * 1000).toDateString()

export const DateToTimestampParser = value => new Date(value).getTime()

export const DateTimestampParser = value => moment(value).format('ddd MMM DD YYYY - h:mm:ss a')

export const DateParser = value => new Date(value).toDateString()

export const PriceParser = value => `$${parseFloat(value).toFixed(2)}`

export const QuantityParser = value => value

export const toBase64 = (file, callback) => {
  const reader = new FileReader()
  reader.onload = () => {
    callback({ result: reader.result })
  }

  reader.onerror = error => {
    callback({ error })
  }

  reader.readAsDataURL(file)
}

export const ORDER_TYPES = {
  0: 'Direct Cova',
  1: 'Online Pre Paid',
  2: 'Self Serve',
  3: 'Delivery',
}

export const OrderType = orderType => ORDER_TYPES[orderType]

const STRAIN_TYPES = {
  1: 'Indica',
  2: 'Sativa',
  3: 'Hybrid',
  4: 'Blend',
}

export const StrainTypeParser = strainType => STRAIN_TYPES[strainType]

export const ORDER_STATUS = {
  Active: 'Active',
  InCart: 'In Cart',
  Preparing: 'Preparing',
  OnRoute: 'On Route',
  Complete: 'Complete',
  Refunded: 'Refunded',
  Cancelled: 'Cancelled',
  OrderPlaced: 'Order Placed',
}

export const OrderStatus = orderStatus => ORDER_STATUS[orderStatus]


export const DISCOUNT_TYPES = {
  1: 'Single-use',
  3: 'Multi use',
}

export const DiscountType = discountType => DISCOUNT_TYPES[discountType]

export const DISCOUNT_TYPES_DESCRIPTIONS = {
  1: 'Single use, location locked',
  2: 'Single use, any location within the company',
  3: 'Multi use, locked to specific location',
  4: 'Multi use, any location within company',
}

export const DiscountTypeDescription = discountType => DISCOUNT_TYPES_DESCRIPTIONS[discountType]
