import React from 'react'

function TextHolder({
  label,
  value,
  ...rest
}) {
  return (
    <div {...rest}>
      <p className="text-xs text-gray-500 font-medium">{ label }</p>
      <p className="text-gray-800 leading-5 text-sm whitespace-normal">
        { value }
      </p>
    </div>
  )
}

export default TextHolder
