import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import ReactNotification from 'react-notifications-component'
import { BrowserRouter as Router } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import App from './App'
import { store, persistor } from './app/store'
import * as serviceWorker from './serviceWorker'
import './styles/tailwind.css'
import 'react-notifications-component/dist/theme.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
 
library.add(fas)

Modal.setAppElement('#root')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactNotification />
        <DndProvider backend={HTML5Backend}>
          <Router>
            <App />
          </Router>
        </DndProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
