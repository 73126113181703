import React, { useEffect, useMemo, useState } from 'react'

function ScreenColumn({
  column: { title, filter, filtervalue },
  columnNumber,
  products,
  medias,
  config: { pageitems },
  page,
  prevFilter,
  prevFilterValue,
  ColumnComponent,
  setTimeLeft,
  setShowLoadingBar
}) {
  const [isShowMedia, setShowMedia] = useState(false)
  const [startIndex, setstartIndex] = useState(0)
  const [productsFound, setproductsFound] = useState(() =>
    products.filter(
      product => parseInt(product[filter], 10) === parseInt(filtervalue, 10),
    ))

  const [numProcess, setnumProcess] = useState(productsFound.length)
  const mediasToShow = useMemo(() => medias, [medias])

  useEffect(() => {
    const results = products.filter(
      product =>
        filtervalue === '0' ||
        parseInt(product[filter], 10) === parseInt(filtervalue, 10),
    )
    setproductsFound(results)
  }, [products])

  useEffect(() => {
    if (pageitems)
      setShowLoadingBar(false)
      setTimeout(() => {
        setShowLoadingBar(true)
      }, 1000);
      if (page === 1) {
        if (prevFilter === filter && prevFilterValue === filtervalue) {
          setstartIndex(pageitems * (columnNumber - 1))
          setnumProcess(pageitems * columnNumber)
        } else {
          setstartIndex(0)
          setnumProcess(pageitems)
        }
      } else {
        const tempStartIndex = numProcess + 1
        const tempnumProcess = numProcess + pageitems + 1
        if (tempnumProcess > productsFound.length)
          if (prevFilter === filter && prevFilterValue === filtervalue) {
            const finalStartIndex =
              (tempnumProcess - productsFound.length) * (columnNumber - 1)
            const finalNumProcess = finalStartIndex + pageitems
            setstartIndex(
              finalNumProcess > productsFound.length ?
                pageitems * (columnNumber - 1) :
                finalStartIndex,
            )
            setnumProcess(
              finalNumProcess > productsFound.length ?
                pageitems * columnNumber :
                finalNumProcess,
            )
          } else {
            if (mediasToShow && mediasToShow.length > 0) {
              setShowMedia(true)
              return
            }

            setstartIndex(0)
            setnumProcess(pageitems)
          }
        else if (prevFilter === filter && prevFilterValue === filtervalue) {
          setstartIndex(tempStartIndex + pageitems * (columnNumber - 1))
          setnumProcess(
            tempStartIndex + pageitems * (columnNumber - 1) + pageitems,
          )
        } else {
          setstartIndex(tempStartIndex)
          setnumProcess(tempnumProcess)
        }
      }
    if (setTimeLeft)
      setTimeLeft(0)
  }, [page, pageitems, prevFilter, prevFilterValue])

  const productsToShow = useMemo(
    () => productsFound.slice(startIndex, numProcess),
    [productsFound, startIndex, numProcess, pageitems],
  )

 

  return ColumnComponent ? (
    <ColumnComponent
      title={title}
      isShowMedia={isShowMedia}
      productsToShow={productsToShow}
      mediasToShowProps={mediasToShow}
      setstartIndex={setstartIndex}
      setnumProcess={setnumProcess}
      pageitems={pageitems}
      setShowMedia={setShowMedia}
      columnNumber={columnNumber} />
  ) : null
}

export default ScreenColumn
