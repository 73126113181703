import React, { useState, useEffect } from 'react'
import { PriceParser } from '../../app/services/Parsers'
import Title from '../../components/Title'
import styled from 'styled-components'
import Slider from 'react-slick'

const WrapperFullySlider = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  .product-image,
  .slick-slide img {
    height: 100%;
  }
  .slick-slide img {
    object-fit: cover;
  }
  .slick-slide video {
    object-fit: cover;
  }
  .slick-slide .product-image {
    display: none;
  }
  .slick-slide.slick-active.slick-current .product-image {
    display: block;
  }

  .slick-slide video::-webkit-media-controls {
    display: none;
  }

  /* Could Use thise as well for Individual Controls */
  .slick-slide video::-webkit-media-controls-play-button {
    display: none;
  }

  .slick-slide video::-webkit-media-controls-volume-slider {
    display: none;
  }

  .slick-slide video::-webkit-media-controls-mute-button {
    display: none;
  }

  .slick-slide video::-webkit-media-controls-timeline {
    display: none;
  }

  .slick-slide video::-webkit-media-controls-current-time-display {
    display: none;
  }

  .slick-slide video::-webkit-media-controls-overlay-play-button {
    display: none;
  }

  .slick-slide video::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }

  .slick-slide video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  .slick-slide video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
`
const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 23px;
  width: calc(20% - 0.75rem);
  min-width: calc(20% - 0.75rem);
  margin-right: 0.75rem;
  margin-bottom: 2rem;
  .container-wrapper {
    padding: 13px 12px;
    padding-bottom: 12px;
  }
  .gray-infor {
    font-size: 11px;
    font-weight: 500;
  }
  .productName {
    font-size: 19px;
    margin-bottom: 7px;
  }

  .price-tag-variation {
    margin-right: 3px;
    min-width: 100px;
  }

  .productBrand {
    font-size: 13px;
  }

  .wrap-price-tag {
    padding: 5px;
    border-top: 1px solid #ececec;
    .price-tag {
      opacity: 0.9;
      background: #f2f2f2;
      border-radius: 18px;
      font-size: 12px;
      font-weight: 600;
      padding: 5px 8px;
      small {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`

const TagType = styled.div`
  background: #e5e5f6;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  max-height: 26px;
  color: #5252c1;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  text-align: center;
  line-height: 12px;
  padding: 7px 10px;
  margin-top: 7px;
  &.Indica {
    background: #e9f9ff;
    color: #06a8e1;
  }
  &.Sativa {
    background: #f6f0e5;
    color: #e19456;
  }
`

const getProductPrice = variation => {
  if (variation.memberPrice) return variation.memberPrice
  return variation.price
}

const getProductOriginPrice = variation => {
  if (variation.memberPrice) return variation.price
  return variation.originalPrice
}

const ThemeFourthColumns = ({
  title,
  productsToShow,
  columnNumber,
  mediasToShowProps,
  isShowMedia,
  setstartIndex,
  setShowMedia,
  setnumProcess,
  pageitems,
}) => {
  const [showLoadingBar, setLoadingBar] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [progressWidth, setProgressWidth] = useState(-100)
  const sliderRef = React.useRef(null)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // beforeChange: (current, next) => setCurrentSlide(next),
  }
  const mediasToShow = (mediasToShowProps || []).filter(
    e =>
      (e.displaytime && e.displaytime !== 0) ||
      e.url.indexOf('.mp4') > -1 ||
      e.url.indexOf('.mov') > -1,
  )

  useEffect(() => {
    if (
      (productsToShow || productsToShow.length > 0) &&
      !isShowMedia &&
      (!mediasToShow || mediasToShow.length === 0)
    )
      return
    if (!sliderRef) return

    let ww = -100

    if (currentSlide + 1 > mediasToShow.length) {
      sliderRef.current.slickGoTo(0)
      setCurrentSlide(temp => 0)
      setProgressWidth(ww)
      return
    }

    let currentSlideIx = currentSlide

    setLoadingBar(false)
    setTimeout(() => {
      setLoadingBar(true)
    }, 500)

    if (
      (!productsToShow || productsToShow.length === 0 || isShowMedia) &&
      mediasToShow &&
      mediasToShow.length
    )
      setTimeout(() => {
        if (!sliderRef || !mediasToShow[currentSlideIx]) return

        const i =
          100 /
          (mediasToShow[currentSlideIx] ?
            mediasToShow[currentSlideIx].displaytime - 1 :
            5)
        if (
          mediasToShow[currentSlideIx].url.indexOf('.mp4') > -1 ||
          mediasToShow[currentSlideIx].url.indexOf('.mov') > -1
        ) {
          setLoadingBar(false)
          setTimeout(() => {
            setLoadingBar(true)
          }, 500)
          return
        }
        if (currentSlideIx + 1 > mediasToShow.length) {
          ww = -100
          currentSlideIx = 0
          if (sliderRef && sliderRef.current) sliderRef.current.slickGoTo(0)
          setCurrentSlide(temp => currentSlideIx)
          setProgressWidth(ww)
          return
        }

        if (
          mediasToShow[currentSlideIx].url.indexOf('.mp4') > -1 ||
          mediasToShow[currentSlideIx].url.indexOf('.mov') > -1
        ) {
          setLoadingBar(false)
          setTimeout(() => {
            setLoadingBar(true)
          }, 500)
          return
        }

        if (currentSlideIx + 1 > mediasToShow.length) {
          currentSlideIx = 0
          if (sliderRef && sliderRef.current) sliderRef.current.slickGoTo(0)
        } else {
          if (mediasToShow.length === 1 && currentSlideIx === 0) {
            currentSlideIx = 0
            setCurrentSlide(1)
            setLoadingBar(false)
            setTimeout(() => {
              setLoadingBar(true)
            }, 500)
            return
          }
          currentSlideIx += 1
          if (sliderRef && sliderRef.current) {
            sliderRef.current.slickGoTo(currentSlideIx)
            setCurrentSlide(temp => currentSlideIx)
          }
        }
      }, (mediasToShow[currentSlideIx] ? mediasToShow[currentSlideIx].displaytime - 0.5 : 5) * 1000)
  }, [sliderRef, currentSlide, isShowMedia])

  useEffect(() => {}, [currentSlide])

  if (
    isShowMedia ||
    ((!productsToShow || productsToShow.length === 0) &&
      mediasToShow &&
      mediasToShow.length)
  ) {
    if (
      !document.getElementsByClassName('logoTitle') &&
      document.getElementsByClassName('logoTitle')[0]
    )
      document.getElementsByClassName('logoTitle')[0].style.display = 'none'
    return (
      <WrapperFullySlider>
        {showLoadingBar &&
          mediasToShow &&
          mediasToShow.length > 1 &&
          mediasToShow[currentSlide].url.indexOf('.mp4') === -1 &&
          mediasToShow[currentSlide].url.indexOf('.mov') === -1 && (
            <div
              id="progress-bar"
              className="progress-bar-2"
              style={{
                animationDuration: `${
                  mediasToShow[currentSlide] ?
                    mediasToShow[currentSlide].displaytime - 1 :
                    4
                }s`,
              }} />
          )}
        <Slider {...settings} ref={sliderRef}>
          {mediasToShow[currentSlide] && (
            <div
              className="product-image hidden-control"
              key={mediasToShow[currentSlide].name}>
              <div className="flex justify-center product-image video-background">
                {mediasToShow[currentSlide].url.indexOf('.mp4') > -1 ||
                mediasToShow[currentSlide].url.indexOf('.mov') > -1 ? (
                  <video
                    controls="false"
                    // playsInline
                    data-wf-ignore="true"
                    data-object-fit="cover"
                    autoPlay
                    muted
                    className="w-full h-full"
                    poster="https://via.placeholder.com/1x1"
                    onEnded={() => {
                      if (
                        productsToShow &&
                        productsToShow.length > 0 &&
                        currentSlide + 1 >= mediasToShow.length
                      ) {
                        setstartIndex(0)
                        setnumProcess(pageitems)
                        setTimeout(() => {
                          setShowMedia(false)
                        }, 100)
                        return
                      }
                      if (currentSlide + 1 >= mediasToShow.length) {
                        sliderRef.current.slickGoTo(0)
                        setCurrentSlide(temp => 0)
                        return
                      }
                      sliderRef.current.slickGoTo(currentSlide + 1)
                      setCurrentSlide(temp => currentSlide + 1)
                    }}>
                    <source autoPlay src={mediasToShow[currentSlide].url} />
                  </video>
                ) : (
                  <img
                    alt={`${mediasToShow[currentSlide].name}`}
                    src={`${mediasToShow[currentSlide].url}?tr=w-auto,h-auto`}
                    className="w-full object-contain"
                    loading="lazy" />
                )}
              </div>
            </div>
          )}
        </Slider>
      </WrapperFullySlider>
    )
  }
  if (
    !document.getElementsByClassName('logoTitle') &&
    document.getElementsByClassName('logoTitle')[0]
  )
    document.getElementsByClassName('logoTitle')[0].style.display = 'block'
  return (
    <div className="mt-3">
      <h4 className={`text-3xl text-left menuTitleCol${columnNumber}`}>
        {productsToShow && productsToShow.length > 0 ?
          productsToShow[0].category :
          ''}
      </h4>
      <div
        className="flex flex-wrap"
        style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}>
        {productsToShow.map(product => (
          <>
            <Wrapper className="">
              <div className="container-wrapper">
                <div className="flex justify-between mb-4">
                  <TagType className={product.strain}>{product.strain}</TagType>
                  <span className="mt-1 ">
                    <div className="mr-2 gray-infor ">
                      <span className="">THC:</span> {product.thc}
                      {product.thccbddisplay}
                    </div>
                    <div className="gray-infor ">
                      <span className="">CBD:</span> {product.cbd}
                      {product.thccbddisplay}
                    </div>
                  </span>
                </div>

                <div className="flex justify-between">
                  <div className="px-2 flex-1">
                    <Title
                      title={product.productName}
                      titleSize="text-base productName" />
                    <span className="productBrand">{product.brandname}</span>
                  </div>
                </div>
              </div>
              <div className="flex wrap-price-tag flex-wrap">
                {product.variations.map((variation, i) => (
                  <p className="m-2 mr-0 price-tag" key={`tag-i-${i}`}>
                    <small className="price-tag-variation">
                      {variation.displayname}{' '}
                    </small>{' '}
                    {PriceParser(getProductPrice(variation))}
                  </p>
                ))}
              </div>
            </Wrapper>
          </>
        ))}
      </div>
      {/* {mediasToShow && mediasToShow.length > 0 && (
        <>
          <h4 className={`text-3xl text-left menuTitleCol${columnNumber}`}>
            Medias
          </h4>
          <div
            className="flex flex-wrap"
            style={{ marginLeft: "-0.75rem", marginRight: "-0.75rem" }}
          >
            {mediasToShow.map((media) => (
              <>
                <Wrapper className="">
                  <div className="container-wrapper">
                    <div className="flex justify-between mb-4">
                      <TagType className={media.name}>{media.name}</TagType>
                    </div>

                    <div className="flex justify-between">
                      <div className="px-2 flex-1">
                        <Title
                          title={`${media.displaytime} Seconds`}
                          titleSize="text-base productName"
                        />
                        <div
                          style={{
                            marginTop: "15px",
                            padding: "15px",
                            borderRadius: "6px",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={media.url}
                            alt=""
                            sty={{
                              borderRadius: "12px",
                              boxShadow: "0px 0px 10px #ddd",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Wrapper>
              </>
            ))}
          </div>
        </>
      )} */}
    </div>
  )
}

export default ThemeFourthColumns
