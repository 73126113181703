import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import AsyncContent from '../components/AsyncContent'
import { useState } from 'react'
import Api from '../app/services/Api'
import useNotifications from '../hooks/useNotifications'
import Card from '../components/Card'
import DiscoveryProducts from './discovery/DiscoveryProducts'
import { setDevice, setProducts } from '../app/reducers/DiscoverySlice.js'
import DiscoveryHead from './discovery/DiscoveryHead'
import useWebSockets from '@Hooks/useWebSockets'

function DiscoveryShow() {
  const dispatch = useDispatch()
  const { notifyError } = useNotifications()
  const history = useHistory()
  const { id } = useParams()
  const intervalRefLoad = useRef()
  const device = useSelector(state => state.discovery.discovery.device)
  const products = useSelector(
    state => state.discovery.discovery?.products || [],
  )
  const [showProducts, setShowProducts] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [loyalty, setLoyalty] = useState(null)
  const [isOnline, setIsOnline] = useState(true)
  const [wsData, connect, subscribe, , connected] = useWebSockets()
  useIdleTimer({
    timeout: 30000,
    onIdle: () => {
      setShowProducts(device?.featurebanner === null)
    },
    debounce: 500,
  })
  const api = new Api()

  const load = (showLoading = true) => {
    setLoading(showLoading)
    setError(false)
    setShowProducts(false)
    api
      .get(`public/discovery/device/${id}`)
      .then(({ data: { body, statusCode } }) => {
        setLoading(false)
        if (statusCode === 401)
          notifyError('Something went wrong', 'lookupcode wrong')
        else {
          try {
            window.Android.setDeviceName(body.device.devicename)
          } catch (err) {}
          dispatch(setDevice(body.device))
          dispatch(setProducts(body.products))
          setLoyalty(body.loyalty)
          setShowProducts(body?.device?.featurebanner === null)
          connect()
        }
      })
      .catch(error => {
        setLoading(false)
        if (products.length > 0)
          // We have cached products, display those
          return

        setError(
          'Network error while trying to load products, and there was no cache. Ensure this device is connected to the internet and click try again.',
        )
      })
  }

  useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(true)
    })
    window.addEventListener('offline', () => {
      setIsOnline(false)
    })
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isOnline) load(false)
    }, (device?.reloadTime || 600) * 1000)
    intervalRefLoad.current = intervalId
    return () => {
      clearInterval(intervalRefLoad.current)
    }
  }, [device, isOnline])

  useEffect(() => {
    if (navigator.onLine) load()
  }, [])

  useEffect(() => {
    if (wsData && wsData === '"refresh"') load(false)
  }, [wsData])

  useEffect(() => {
    if (connected !== null && device)
      if (connected)
        subscribe(`discovery_${device.notificationId}`, {
          location: device.locationid,
        })
      else connect()
  }, [connected])
  return (
    <div className="min-h-screen flex flex-col">
      <DiscoveryHead loyalty={loyalty} />

      <AsyncContent loading={loading} error={error} load={load}>
        <div className="container mx-auto px-4 discovery-container">
          {device.featurebanner && !showProducts && (
            <div className="w-full max-w-2xl text-center mx-auto">
              <button type="button" onClick={() => setShowProducts(true)}>
                <Card>
                  <img
                    src={device.featurebanner}
                    alt={device.devicename}
                    className="w-full max-w-full mb-4" />

                  <span className="btn btn-primary display-block">
                    Discover Products
                  </span>
                </Card>
              </button>
            </div>
          )}
          {(!device.featurebanner || showProducts) && (
            <DiscoveryProducts
              loyalty={loyalty}
              locationid={device.locationid}
              deviceId={device.notificationId}
              products={products}
              onAutomaticClose={() => {
                setShowProducts(device?.featurebanner === null)
              }} />
          )}
        </div>
      </AsyncContent>
    </div>
  )
}

export default DiscoveryShow
