import React, { useMemo, useState, useEffect } from "react";
import ScreenColumn from "./ScreenColumn";
import ThemeFourthColumns from "./ThemeFourthColumns";
import styled from "styled-components";

const TitlePage = styled.h3`
  font-size: 60px;
  font-weight: 600;
  color: #242424;
  letter-spacing: -0.88px;
  line-height: 60px;
`;

const ThemeFourth = ({
  pageCounter,
  config,
  config: { menutitle },
  columns,
  page,
  products,
  found,
  brand,
  medias,
  showAndroidSelector,
}) => {
  const columnsContainerClass = useMemo(() => {
    if (!found) return "";
    if (!columns) return "grid-cols-4";

    const totalColumns = columns.length;

    if (totalColumns === 2) return "grid-cols-2";
    if (totalColumns === 3) return "grid-cols-3";

    return "grid-cols-4";
  }, [found, columns]);

  const [timeLeft, setTimeLeft] = useState(0);
  const [showLoadingBar, setShowLoadingBar] = useState(false);
  
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setTimeLeft((t) => t + 1);
      },
      config ? config.pagetime * 10.05 : 5 * 10.05
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setTimeLeft(0);
  }, [page]);

  const [totalPage, setTotalPage] = useState(
    () => products.length / config.pageitems
  );

  const [productsShow, setProductsShow] = useState([]);

  useEffect(() => {
    if (products) {
      const data = [];
      products.forEach((d) => {
        data[d.strain] = data[d.strain] || [];
        data[d.strain].push(d);
      });

      setProductsShow(data);
    }
  }, [products]);
  const getPageCountPercent = () => `${100 - timeLeft}%`;
  return (
    <div className="min-h-screen bg-gray-50 py-4 px-4 sm:px-6 lg:px-8 relative menuBackground">
      <div className="flex justify-between">
        <TitlePage className="text-4xl text-left menuTitle">
          {menutitle}
        </TitlePage>
        <div className="relative logoTitle">
          <div className="indicator-waio" />
          {/* {totalPage} */}
          <img
            src={brand.logo}
            alt={brand.companyName}
            style={{ width: 120 }}
          />
        </div>
      </div>
      <div className="flex justify-center flex-col">
        {columns ? (
          columns.map((column, i) => (
            <ScreenColumn
              key={`column-${i}`}
              config={config}
              column={column}
              page={page}
              columnNumber={i + 1}
              prevFilter={columns[i - 1]?.filter}
              prevFilterValue={columns[i - 1]?.filtervalue}
              products={products}
              medias={medias}
              setTimeLeft={setTimeLeft}
              ColumnComponent={ThemeFourthColumns}
              setShowLoadingBar={setShowLoadingBar}
            />
          ))
        ) : (
          <ScreenColumn
            config={{}}
            column={1}
            page={1}
            columnNumber={4}
            prevFilter={{}}
            prevFilterValue={{}}
            products={products}
            medias={medias}
            setTimeLeft={setTimeLeft}
            ColumnComponent={ThemeFourthColumns}
            setShowLoadingBar={setShowLoadingBar}
          />
        )}
      </div>

      {showLoadingBar && (
        <div
          id="progress-bar"
          className="progress-bar-2"
          style={{
            animationDuration: `${config.pagetime - 1}s`,
            position: 'fixed',
            // transition: `${mediasToShow[currentSlide].displaytime}s all`,
          }}
        />
      )}
      {/*         
      <div
        className="progress-bar"
        style={{ right: `${getPageCountPercent()}` }}>
        {' '}
      </div> */}

      {/* <div className="p-2 absolute top-0 right-0">
        <button type="button" onClick={() => showAndroidSelector()}>
          <span>{pageCounter}</span>
        </button>
      </div> */}
    </div>
  );
};

export default ThemeFourth;
