import React from 'react'

function Card({ className, transparent, image, children, footer, actions }) {
  return (
    <div className={`card ${className} ${transparent ? ' card--transparent' : ''} relative flex flex-col justify-between`}>
      {image && (
        <div className="overflow-y-hidden rounded-tl-3xl rounded-tr-3xl h-44">
          <img src={image} alt="card" className="h-44 w-auto mx-auto" />
        </div>
      )}
      <div className="card-body">
        {children}
      </div>

      {footer && (footer)}

      {actions && (<div className="absolute top-0 right-0"> {actions} </div>)}
    </div>
  )
}

export default Card
