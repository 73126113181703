import { createSlice } from '@reduxjs/toolkit'

export const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    isAuthenticated: false,
    token: null,
    user: {},
    timestamp: null,
  },
  reducers: {
    login: (state, { payload: data }) => {
      const newState = state
      newState.isAuthenticated = true
      newState.token = data.sessionToken
      newState.user = data
      newState.company = {
        id: data.companyId,
        name: data.companyName,
      }
      newState.store = {
        id: data.baseStore,
        name: data.baseStoreName,
        delivery: data.baseStoreDelivery,
        region: data.companyStores.find(store => store.id === data.baseStore)?.region,
      }
      newState.permissions = data.access
      newState.stores = data.companyStores
      newState.companyFeatures = data.companyFeatures
    },
    logout: state => {
      const newState = state
      newState.isAuthenticated = false
      newState.token = null
      newState.user = {}
      newState.company = {}
      newState.store = {}
      newState.permissions = {}
      newState.stores = []
    },
    setStore: (state, action) => {
      const newState = state
      newState.store = action.payload
    },
    setTimestamp: (state, { payload }) => {
      const newState = state
      newState.timestamp = payload
    },
    setRefreshSession: (state, { payload: data }) => {
      const newState = state
      newState.isAuthenticated = true
      newState.token = data.sessionToken
      newState.user = data
      newState.company = {
        id: data.companyId,
        name: data.companyName,
      }

      const storeId = data?.setLocation || data.baseStore

      newState.store = data.companyStores.find(companyStore => companyStore.id == storeId)

      newState.permissions = data.access
      newState.stores = data.companyStores
      newState.companyFeatures = data.companyFeatures
    },
  },
})

export const { login, logout, setStore, setTimestamp, setRefreshSession } = LoginSlice.actions

export default LoginSlice.reducer
