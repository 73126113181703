import { store } from 'react-notifications-component'

const useNotifications = () => {
  const notify = (title, message = '', type = 'success') => (
    store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    })
  )

  const notifySuccess = (title, message) => {
    notify(title, message || 'Successful', 'success')
  }

  const notifyError = (title, message) => {
    notify(title, message || 'Please try again.', 'danger')
  }

  const notifyInfo = (title, message) => {
    notify(title, message || 'Somenthing is about to happend', 'info')
  }

  return { notify, notifySuccess, notifyError, notifyInfo }
}

export default useNotifications
