import React from 'react'
import Label from './Label'
import TextHolder from './TextHolder'

const TextField = ({
  onChange,
  onBlur,
  value,
  placeholder,
  name,
  type,
  label,
  required,
  disabled,
  nolabel,
  autocomplete,
  showAsText = false,
  form,
  ...rest
}) => {
  const onChangeAction = event => {
    if (onChange)
      onChange(event.target.value)
  }

  const onBlurAction = event => {
    if (onBlur)
      onBlur(event)
  }

  if (showAsText)
    return (
      <TextHolder label={label} value={value} />
    )

  return (
    <div {...rest}>
      { !nolabel && (<Label name={name}>{label}</Label>)}
      <input
        type={type}
        id={name}
        name={name}
        required={required}
        disabled={disabled}
        className="appearance-none rounded-3xl relative block w-full px-3 py-2 border border-gray-200 bg-gray-100 placeholder-gray-400 text-gray-800 outline-none text-sm disabled:cursor-not-allowed"
        placeholder={placeholder}
        value={value}
        autoComplete={autocomplete || name}
        onChange={onChangeAction}
        onBlur={onBlurAction}
        form={form} />
    </div>
  )
}

export default TextField
