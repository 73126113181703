import React from 'react'
import TextHolder from '../../components/TextHolder'
import Card from '../../components/Card'
import Title from '../../components/Title'
import comingsoon from '../../img/comingsoon.png'
import Modal from '../../components/Modal'
import ProductDetail from './ProductDetail'
import Api from '../../app/services/Api'

function DiscoveryProducts({
  products,
  onAutomaticClose,
  locationid,
  deviceId,
  loyalty,
}) {
  const api = new Api()
  const {
    productName,
    strain,
    category,
    image,
    variations,
    productId,
    favorite,
    thc,
    cbd,
    thccbddisplay,
    iscannabis,
    sensoryjar,
    hasstock,
    favoriteModule,
    brandname,
    onsale,
  } = products
  const onViewProduct = id => {
    api
      .get(
        `analytics/track?action=ProductDetails&device_id=${deviceId}&additional=${id}&location_id=${locationid}`,
      )
      .then(({ data: { body, statusCode } }) => {})
  }
  return (
    <div className="grid gap-0 grid-cols-4">
      {products.map(product => (
        <div className="w-full px-0">
          <Modal
            customButton={
              <button
                type="button"
                className="outline-none focus:outline-none active:outline-none w-full">
                <div
                  className="relative outline-none m-1 lg:m-3 product-card-2"
                  onClick={() => onViewProduct(product.id)}>
                  <div className="w-full rounded-none flex flex-col" />

                  <div className="product-card-top">
                    <div className="flex justify-between items-center px-1 lg:px-3 py-2 lg:py-3 product-card-badge-height">
                      <div
                        className={
                          product.iscannabis === 1 || product.straintype ?
                            `flex badge product-badge badge-${product.straintype}` :
                            ''
                        }>
                        {product.straintype}
                        {product.iscannabis === 1 && (
                          <div className="flex-auto">
                            {/* <img src="/weedfiveleaf.png" alt="weed leaf" className="h-4 w-4 lg:h-5 lg:w-5" /> */}
                          </div>
                        )}
                        <span className="flex-auto">{strain}</span>
                      </div>
                      {/* {product.onsale === '1' && (
                        <div className="badge red-badge">Sale</div>
                      )} */}

                      {product.sensoryjar && (
                        <div className=" badge product-badge sensory-badge">
                          {product.sensoryjar}
                        </div>
                      )}
                    </div>

                    <div className="flex justify-center items-center h-16 lg:h-36 product-card-image">
                      <img
                        alt={`${product.productname} by ${product.brandname}`}
                        loading="eager"
                        className="object-contain w-16 h-16 lg:h-48 lg:w-48"
                        src={
                          product.images.length > 0 ?
                            `${product.images[0]}?tr=w-450,h-auto` :
                            comingsoon
                        } />
                    </div>
                  </div>

                  <div className="infor-data h-full grid grid-cols-1 items-end md:px-2 pt-1 lg:py-2 tracking-tighter lg:tracking-tight">
                    <div className="flex flex-col">
                      <div className="product-card-title text-center">
                        {product.productname}
                      </div>
                      <div className="thc-cbd-min">
                        {product.iscannabis === 1 &&
                          ![null, 'null'].includes(thccbddisplay) && (
                            <div className="flex justify-center px-2 product-card-thc">
                              <span className="">
                                THC {product.thc || '?'}
                                {product.thccbddisplay}
                              </span>

                              <span className=" ml-3">
                                CBD {product.cbd || '?'}
                                {product.thccbddisplay}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className="product-card-sub-text text-center">
                        <span>
                          {product.brandname} | {product.category}
                        </span>
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <button type="button" className="card-select-size">
                        View More
                      </button>
                    </div>
                  </div>

                  {/* <div
                  image={product.images.length > 0 ? product.images[0] : comingsoon}
                  key={`product-${product.productname}`}>
                  <div className="text-center">
                    <Title
                      title={product.productname}
                      titleSize="text-base mb-3 h-5" />

                    <div className="flex justify-center h-16">
                      <TextHolder label="Category" value={product.category} className="mr-4" />
                      <TextHolder label="Brand" value={product.brandname} className="mr-4" />
                      <TextHolder label="Strain" value={product.straintype} />
                    </div>

                    <div className="text-center mt-3">
                      <button type="button" className="btn btn-primary btn-sm">
                        Discover
                      </button>
                    </div>
                  </div>
                </div> */}
                </div>
              </button>
            }
            title=" "
            buttonClass="btn-primary btn-sm"
            className="modal-7xl"
            childrenProps>
            {props => (
              <ProductDetail
                onAutomaticClose={onAutomaticClose}
                loyalty={loyalty}
                {...product}
                {...props} />
            )}
          </Modal>
        </div>
      ))}
    </div>
  )
}

export default DiscoveryProducts
