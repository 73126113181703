import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AsyncContent from '../components/AsyncContent'
import DiscoveryLogin from './discovery/DiscoveryLogin'
import DiscoveryLocations from './discovery/DiscoveryLocations'
import { useHistory } from 'react-router-dom'

function Discovery() {
  const history = useHistory()
  const device = useSelector(state => state.discovery.discovery.device)

  useEffect(() => {
    if (Object.keys(device).length > 0)
      history.replace({ pathname: `/discovery/${device.deviceid}` })
  }, [])

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <h1 className="text-center text-6xl leading-9 font-extrabold text-gray-900">
            WAIO
          </h1>
          <h2 className="mt-6 text-center text-3xl leading-9 text-gray-600">
            Discovery
          </h2>
        </div>

        <DiscoveryLogin>
          {({ load, data, error, loading }) => (
            <AsyncContent loading={loading} error={error} load={load}>
              {data && <DiscoveryLocations {...data} />}
            </AsyncContent>
          )}
        </DiscoveryLogin>
      </div>
    </div>
  )
}

export default Discovery
