import React, { useMemo } from 'react'
import ScreenColumn from './ScreenColumn'
import ThemeTwoColumns from './ThemeTwoColumns'

const ThemeTwo = ({ pageCounter, config, columns, page, products, found, showAndroidSelector }) => {
  const columnsContainerClass = useMemo(() => {
    if (!found) return ''

    const totalColumns = columns.length

    if (totalColumns === 2) return 'grid-cols-2'
    if (totalColumns === 3) return 'grid-cols-3'

    return 'grid-cols-2'
  }, [found, columns])

  return (
    <div className="min-h-screen bg-gray-50 py-4 px-4 sm:px-6 lg:px-8 relative menuBackground">
      <h3 className="text-4xl text-center menuTitle m-0">{config.menutitle}</h3>

      <div className={`grid col-span-4 ${columnsContainerClass} justify-center`}>
        {columns.map((column, i) => (
          <ScreenColumn
            key={`column-${i}`}
            config={config}
            column={column}
            page={page}
            columnNumber={i + 1}
            prevFilter={columns[i - 1]?.filter}
            prevFilterValue={columns[i - 1]?.filtervalue}
            products={products}
            ColumnComponent={ThemeTwoColumns} />
        ))}
      </div>

      <div className="p-2 absolute top-0 right-0">
        <button type="button" onClick={() => showAndroidSelector()}>
          <span>{pageCounter}</span>
        </button>
      </div>
    </div>
  )
}

export default ThemeTwo
