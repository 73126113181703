/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import Api from '../../app/services/Api'
import LoadingHolder from '../../components/LoadingHolder'
import TextField from '../../components/TextField'
import useNotifications from '../../hooks/useNotifications'

function MailingListForm({ device, onRequestClose }) {
  const { notifySuccess, notifyError } = useNotifications()
  const [name, setName] = useState()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const api = new Api()
  const save = event => {
    event.preventDefault()
    setLoading(true)
    api.post(`/public/discovery/device/${device.deviceid}/subscribe`, { name, email })
    .then(({ data: { body } }) => {
      setLoading(false)
      if (body) {
        notifySuccess('Successfully subscribed')
        onRequestClose()
      } else
        notifyError('Something went wrong', body?.message)
    })
  }

  return (
    <div className="">
      <form onSubmit={save}>
        <TextField
          label="Name"
          type="text"
          name="devicename"
          placeholder="Name"
          value={name}
          onChange={value => setName(value)}
          autocomplete="off"
          className="mb-6" />

        <TextField
          label="Email *"
          type="email"
          name="email"
          required
          placeholder="email"
          value={email}
          onChange={value => setEmail(value)}
          autocomplete="off"
          className="mb-6" />

        <div className="flex items-center justify-end">
          <button className="btn btn-secondary ml-3" type="button" onClick={onRequestClose}>
            Cancel
          </button>
          <button className="btn btn-primary ml-3" type="submit" disabled={loading}>
            <LoadingHolder loading={loading} label="subscribe" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default MailingListForm
