/* eslint-disable no-prototype-builtins */
import React, { useState, useCallback } from 'react'
import ReactModal from 'react-modal'

const Modal = ({
  customButton,
  openModalLabel,
  title,
  children,
  className,
  buttonClass,
  childrenProps,
  renderOpened,
  hideButton,
  onOpenModal,
  disabled,
  onCloseModal,
}) => {
  const [showModal, setShowModal] = useState(renderOpened || false)

  const buttonClassNames = useCallback(() => {
    const classes = ['btn', 'btn-sm']

    if (buttonClass)
      classes.push(buttonClass)
    else
      classes.push('btn-primary')

    return classes
  }, [buttonClass])

  const classNames = useCallback(() => {
    const classes = ['modal-dialog']

    if (className)
      classes.push(className)
    else
      classes.push('btn-primary')

    return classes
  }, [className])

  const handleOpenModal = () => {
    setShowModal(true)
    if (onOpenModal) onOpenModal()
  }
  const handleCloseModal = () => {
    setShowModal(false)
    if (onCloseModal) onCloseModal()
  }

  return (
    <>
      { !hideButton && (
        <>
          { customButton ? (
            React.cloneElement(customButton, { onClick: handleOpenModal, disabled })
          ) : (
            <button onClick={handleOpenModal} type="button" className={buttonClassNames().join(' ')} disabled={disabled}>
              {openModalLabel || 'Open Modal'}
            </button>
          )}
        </>
      )}
      <ReactModal
         isOpen={showModal}
         contentLabel="onRequestClose Example"
         className={classNames().join(' ')}
         overlayClassName="modal-overlay"
         onRequestClose={handleCloseModal}
         id="waio-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="leading-none text-2xl">{title || 'Modal Title'}</h3>
            <button type="button" className="modal-close" onClick={handleCloseModal}>&times;</button>
          </div>
          <div className="modal-body pt-0 pb-5">
          { childrenProps ? children({ onRequestClose: handleCloseModal }) : children }
          </div>
        </div>
      </ReactModal>
    </>
  )
}
export default Modal
