import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Api from '../../app/services/Api'
import LoadingHolder from '../../components/LoadingHolder'
import TextField from '../../components/TextField'
import useNotifications from '../../hooks/useNotifications'

function PasswordPopUp({ onCancel, onResetDevice }) {
  const device = useSelector(state => state.discovery.discovery.device)

  const { notifyError } = useNotifications()
  const [lookupcode, setlookupcode] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [loading, setLoading] = useState(false)
  const api = new Api()

  const load = event => {
    event.preventDefault()
    setLoading(true)
    api.get(`public/discovery/locations/${lookupcode}`)
    .then(({ data: { body, statusCode } }) => {
      setLoading(false)
      if (body?.company)
        setShowOptions(true)
      else
        notifyError('Something went wrong', 'lookupcode wrong')
    })
  }

  if (showOptions) return (
    <div className="flex items-center justify-center">
      <button className="btn btn-secondary mr-3" type="button" onClick={() => {
        onCancel()
        setShowOptions(false)
      }}>
        Cancel
      </button>
      <button type="button" className="btn btn-primary" onClick={onResetDevice}>
        Reset Device
      </button>
    </div>
  )

  return (
    <form className="mt-8" action="#" method="POST" onSubmit={load}>
      Device Name: {device.devicename}
      <TextField
        label="Password"
        type="password"
        name="lookupcode"
        placeholder="Lookup Code"
        value={lookupcode}
        onChange={value => setlookupcode(value)}
        className="mb-6" />

      <div className="flex items-center justify-end">
        <button className="btn btn-secondary mr-3" type="button" onClick={() => {
          onCancel()
          setShowOptions(false)
        }}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading}>
          <LoadingHolder loading={loading} label="Send" />
        </button>
      </div>
    </form>
  )
}

export default PasswordPopUp
