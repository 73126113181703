import axios from 'axios'

export default class Api {
  constructor(token = null, companyId = null, storeId = null) {
    this.token = token
    this.companyId = companyId
    this.storeId = storeId
    axios.defaults.timeout = 15000
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: this.buildHeaders(),
    })
  }

  post(url, params = {}, headers = {}) {
    return this.client.post(url, params, { headers })
  }

  get(url, headers = {}, params = {}) {
    return this.client.get(url, { headers, params })
  }

  put(url, params = {}, headers = {}) {
    return this.client.put(url, params, { headers })
  }

  delete(url, headers = {}) {
    return this.client.delete(url, { headers })
  }

  buildHeaders() {
    const headers = { 'Content-Type': 'application/json' }

    if (this.token) headers.Authorization = `Bearer ${this.token}`
    if (this.companyId) headers['waio-company'] = this.companyId
    if (this.storeId) headers['waio-location'] = this.storeId

    return headers
  }
}
