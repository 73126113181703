import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function LoadingHolder({ loading, label }) {
  if (loading)
    return (
      <FontAwesomeIcon icon="spinner" spin size="lg" />
    )


  return (label)
}

export default LoadingHolder
