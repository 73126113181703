import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { login, setTimestamp } from '../app/reducers/LoginSlice'
import Api from '../app/services/Api'
import LoadingHolder from '../components/LoadingHolder'
import useNotifications from '../hooks/useNotifications'

import { useHistory, useLocation, Link } from 'react-router-dom'

function Login() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { notifySuccess, notifyError } = useNotifications()

  const history = useHistory()
  const location = useLocation()

  const { from } = location.state || { from: { pathname: '/configuring' } }

  const doLogin = event => {
    setLoading(true)
    event.preventDefault()
    const api = new Api()
    api.post('/auth/login', { email, password })
    .then(({ data, data: { body, body: { basepage } } }) => {
      setLoading(false)
      if (data.statusCode === 401)
        notifyError('Email or Password wrong')
      else {
        notifySuccess('Successfully signed in')
        dispatch(login(body))
        dispatch(setTimestamp(null))
        history.replace(basepage ? { pathname: basepage } : from)
      }
    })
    .catch(() => {
      setLoading(false)
      notifyError('something whent wrong')
    })
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <h1 className="text-center text-6xl leading-9 font-extrabold text-gray-900">
            WAIO
          </h1>
          <h2 className="mt-6 text-center text-3xl leading-9 text-gray-600">
           DISCOVERY PORTAL - DO NOT USE
          </h2>
        </div>
      </div>
    </div>
  )
}

export default Login
