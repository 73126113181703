import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Api from "@Services/Api";
import ThemeOne from "./public_screens/ThemeOne";
import ThemeTwo from "./public_screens/ThemeTwo";
import ThemeThree from "./public_screens/ThemeThree";
import ThemeFourth from "./public_screens/ThemeFourth";
import useWebSockets from "@Hooks/useWebSockets";

const THEMES = {
  "theme-1": ThemeFourth,
  "theme-2": ThemeTwo,
  "theme-3": ThemeThree,
  "theme-4": ThemeFourth,
};

function PublicScreens() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [found, setFound] = useState(false);
  const [columns, setColumns] = useState([]);
  const [config, setConfig] = useState({});
  const [brand, setBrand] = useState({});
  const [products, setProducts] = useState([]);
  const [medias, setMedias] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCounter, setPageCounter] = useState(null);
  const [pagetime, setPagetime] = useState(null);
  const intervalRef = useRef();
  const intervalRefLoad = useRef();
  const { id } = useParams();
  const [wsData, connect, subscribe, , connected] = useWebSockets();
  const api = new Api();

  const showAndroidSelector = () => {
    if (window.Android) window.Android.changeScreenID();
  };

  const addCss = ({ css }) => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");

    head.appendChild(style);

    style.type = "text/css";
    if (style.styleSheet) style.styleSheet.cssText = css;
    else style.appendChild(document.createTextNode(css));
  };

  const loadScreens = (showLoading = true) => {
    if (showLoading) setLoading(true);
    setError(false);
    api.get(`/public/screens/${id}`).then(({ data: { body, statusCode } }) => {
      setLoading(false);
      if (statusCode !== 200) {
        setErrorCode(statusCode);
        setError(body?.message);
        setFound(false);
      } else {
        connect();
        setFound(true);
        setMedias(body.media);
        setBrand(body.branding);
        setColumns(body.columns);
        setConfig(body.config);
        setProducts(body.products);
        if (showLoading) {
          setPagetime(body.config.pagetime);
          setPageCounter(body.config.pagetime);
        }
        addCss(body.config);
      }
    });
  };

  const ThemeComponent = useMemo(() => {
    const { theme } = config;
    if (!theme) {
      return THEMES["theme-4"];
    }
    return THEMES[theme];
  }, [config]);

  useEffect(() => loadScreens(), []);

  useEffect(() => {
    if (pageCounter) {
      const intervalId = setInterval(() => {
        const newCounter = pageCounter - 1;
        if (newCounter <= 0) {
          setPageCounter(pagetime);
          setPage(page + 1);
        } else setPageCounter(newCounter);
      }, 1000);
      intervalRef.current = intervalId;
      return () => {
        clearInterval(intervalRef.current);
      };
    }

    if (intervalRef.current) clearInterval(intervalRef.current);
  }, [pageCounter, pagetime, page]);

  useEffect(() => {
    if (config) {
      const intervalId = setInterval(() => {
        loadScreens(false);
      }, (config?.reloadTime || 300) * 1000);
      intervalRefLoad.current = intervalId;
      return () => {
        clearInterval(intervalRefLoad.current);
      };
    }
  }, [config]);

  useEffect(() => {
    if (wsData && wsData === '"refresh"') loadScreens(false);
  }, [wsData]);

  useEffect(() => {
    if (connected !== null)
      if (connected) subscribe(`tvmenu_${id}`, { location: config.locationid });
      else connect();
  }, [connected]);

  if (loading)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h1 className="text-6xl">Loading screen...</h1>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h1 className="text-6xl">{errorCode}</h1>
          <p className="text-2xl">{error}</p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => showAndroidSelector()}
          >
            Open Android Screen Selector
          </button>
        </div>
      </div>
    );

  return (
    <ThemeComponent
      pageCounter={pageCounter}
      columns={columns}
      config={config}
      page={page}
      found={found}
      showAndroidSelector={showAndroidSelector}
      brand={brand}
      products={products}
      medias={medias}
    />
  );
}

export default PublicScreens;
