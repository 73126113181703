//import React, { useState } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
//import { login } from '../app/reducers/LoginSlice'
//import Api from '../app/services/Api'
//import LoadingHolder from '../components/LoadingHolder'
//import Link from '../components/Link'

import React, { useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import LoadingHolder from './components/LoadingHolder'
import useNotifications from '../../hooks/useNotifications'
import Modal from '../../components/Modal'
import Terms from './Terms'

//import Link from '../components/Link'
import Api from '../../app/services/Api'



function Login({close, loyalty, onRequestClose}) {
  //const dispatch = useDispatch()
  //const { locationid } = useSelector(state => (state.selfserve.selflogin.configured ? state.selfserve.selfstores : state.root.stores).store)
  //const { id: companyId } = useSelector(state => (state.selfserve.selflogin.configured ? state.selfserve.selfstores : state.root.stores).company)
  //const [loading, setLoading] = useState(false)
  //const [email, setEmail] = useState('')
  //const [password, setPassword] = useState('')
  //const history = useHistory()
  //const location = useLocation()

  //const { from } = location.state || { from: { pathname: '/' } }
  const device = useSelector(state => state.discovery.discovery.device)
  const locationid = device.locationId
  //const { id: companyId } = useSelector(state => (state.selfserve.selflogin.configured ? state.selfserve.selfstores : state.root.stores).company)
  const { notifySuccess, notifyError } = useNotifications()
  const location = useSelector(state => state.discovery.discovery.location)

  const [firstname, setFisrtName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const address = ' '
  const [password, setPassword] = useState('')
  // const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [terms, setTerms] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  //const isAuthenticated = useSelector(state => state.root.login.isAuthenticated)
  //const storeid = useSelector(state => (state.selfserve.selflogin.configured ? state.selfserve.selfstores : state.root.stores).store.locationid)
  //const companyStores = useSelector(state =>(state.selfserve.selflogin.configured ? state.selfserve.selfstores : state.root.stores).companyStores.filter(store=>store.id === storeid)[0])
  //const store = companyStores


  const { from } = location.state || { from: { pathname: '/' } }


  const doSignUp = event => {
    event.preventDefault()
    if (!terms) return notifyError('You must agree the Terms & Conditions')
    // if (password !== passwordConfirmation) return notifyError('Passwords do not match')

    setLoading(true)

    const params = { firstname, lastname, email, password, address, phone,
      locationid }
    
    const api = new Api()
    api.post(`/public/discovery/device/${device.deviceid}/register`, params)
    .then(({ data: { body } }) => {
      setLoading(false)
      if (body) {
        notifySuccess('Successfully registered')
        onRequestClose()
      } else
        notifyError('Something went wrong', body?.message)
    })
    /*api.post('/user/register', params, {
      'waio-location': locationid,
      'waio-company': companyId,
    })
    .then(({ data }) => {
      if (data.statusCode !== 200) {
        if(data.statusCode === 401) notifyError('Email already registered')
        else notifyError('An error has occured')
        setLoading(false)
      } else{
        notifySuccess('Sucessfully Sign Up.')
        //history.replace(from)
        //close()
        //window.location.reload();
      }
    })*/
  }

  const loginDiv =  null
  const signupDiv = <form className="flex flex-col max-w-md w-full h-full" method="POST" onSubmit={doSignUp}>
    <div className="text-center">
    {loyalty?<span className="badge black-badge"> {loyalty.loyaltyName}</span>:null}
    {/* <span className="badge black-badge">Plant Perks by One Plant</span> */}
      <h2 className="text-3xl font-semibold pt-1">
        Become a Member
      </h2>
      {<span className="">Start Saving Instantly!</span>}

    </div>

    <div className="mt-7">
      <div>
        <input
          aria-label="First Name"
          type="text"
          required
          className="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border-b-2 border-gray-500 placeholder-gray-500 text-gray-900 focus:border-b-2 focus:border-black focus:z-10 sm:text-sm sm:leading-5"
          placeholder="First Name"
          autoComplete="off"
          value={firstname}
          onChange={event => setFisrtName(event.target.value)} />
      </div>

      <div className="mt-5">
        <input
          aria-label="Last Name"
          type="text"
          required
          className="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border-b-2 border-gray-500 placeholder-gray-500 text-gray-900 focus:border-b-2 focus:border-black focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Last Name"
          autoComplete="off"
          value={lastname}
          onChange={event => setLastName(event.target.value)} />
      </div>

      <div className="mt-5">
        <input
          aria-label="Email"
          type="email"
          required
          className="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border-b-2 border-gray-500 placeholder-gray-500 text-gray-900 focus:border-b-2 focus:border-black focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Email"
          autoComplete="off"
          value={email}
          onChange={event => setEmail(event.target.value)} />
      </div>

      <div className="mt-5">
        <input
          aria-label="Password"
          type="password"
          required
          className="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border-b-2 border-gray-500 placeholder-gray-500 text-gray-900 focus:border-b-2 focus:border-black focus:z-10 focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Password"
          autoComplete="off"
          value={password}
          onChange={event => setPassword(event.target.value)} />
      </div>

      {/* <div className="mt-5">
        <input
          aria-label="Confirm Password"
          type="password"
          required
          className="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border-b-2 border-gray-500 placeholder-gray-500 text-gray-900 focus:border-b-2 focus:border-black focus:z-10 focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Confirm Password"
          autoComplete="off"
          value={passwordConfirmation}
          onChange={event => setPasswordConfirmation(event.target.value)} />
      </div> */}

      <div className="mt-5">
        <input
          aria-label="Phone"
          type="text"
          required
          className="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border-b-2 border-gray-500 placeholder-gray-500 text-gray-900 focus:border-b-2 focus:border-black focus:z-10 sm:text-sm sm:leading-5"
          placeholder="Phone"
          autoComplete="off"
          pattern="[0-9]*"
          value={phone}
          onChange={event => setPhone(event.target.value)} />
      </div>

    </div>

    <div className="mt-10 flex items-center justify-center">
      <input type="checkbox" value={terms} onChange={event => setTerms(event.target.checked)} />
      <span className="ml-2 font-medium text-black align-center"> I accept the <Modal
              openModalLabel={"Terms and Conditions"}
              title=" "
              className="modal-3xl"
              buttonClass="terms-link"
              childrenProps>
                {props => <Terms {...props} />}
            </Modal>
            </span>
      <a href="/" className=" text-black font-bold hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150 ml-1"></a>
    </div>

    <div className="mt-10">
      <button
        type="submit"
        className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm leading-5 rounded-lg text-white font-bold bg-black hover:bg-gray-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-gray-700 transition duration-150 ease-in-out"
        disabled={loading}>
        <LoadingHolder loading={loading} label="Sign Up" />
      </button>

      </div>
      
  </form>
  return (
    <div className="w-1/1">
      {showLogin?loginDiv:signupDiv}
  </div>
  )
}

export default Login
