import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import MailingListForm from './MailingListForm'
import RegisterModal from './RegisterModal'
import PasswordPopUp from './PasswordPopUp'
import { setDevice, setLocation } from '../../app/reducers/DiscoverySlice'

function DiscoveryHead({loyalty}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const device = useSelector(state => state.discovery.discovery.device)
  const location = useSelector(state => state.discovery.discovery.location)
  const [counter, setCounter] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const updateCounter = () => {
    const tempCounter = counter + 1
    setCounter(tempCounter)
    if (tempCounter % 3 === 0)
      setOpenModal(true)
  }

  return (
    <div className="w-full mb-4 border-b border-gray-600 discovery-header">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between -mx-2 py-2">
            <button type="button" onClick={() => updateCounter()} className="h-16 px-2">
              <img src={device.brandlogo} alt={device.devicename} className="max-h-full" />
            </button>

  <div className="flex-1">
    <h2 className="device-title text-center">{device.devicetitle}</h2>
  </div>

          <div className="flex text-right px-2">
            {loyalty && loyalty.enabled?<Modal
              openModalLabel={"Sign up for "+loyalty.loyaltyNameShort}
              title=" "
              buttonClass="btn-primary btn-sm"
              childrenProps>
              {props => <RegisterModal device={device} {...props} />}
            </Modal>:<Modal
              openModalLabel="Mailing List"
              title="Subscribe to our Mailing List"
              buttonClass="btn-primary btn-sm"
              childrenProps>
              {props => <MailingListForm device={device} {...props} />}
            </Modal>}
          </div>
        </div>
        {openModal && (
          <Modal
            title="Fill the Password Field"
            renderOpened
            hideButton
            onCloseModal={() => {
              setOpenModal(false)
            }}
            childrenProps>
            {props => (
              <PasswordPopUp onCancel={() => {
                  setOpenModal(false)
                  props.onRequestClose()
                }}
                onResetDevice={() => {
                  setOpenModal(false)
                  dispatch(setLocation({}))
                  dispatch(setDevice({}))
                  history.replace({ pathname: '/discovery' })
                }} />
            )}
          </Modal>
        )}
      </div>
    </div>
  )
}

export default DiscoveryHead
