import React from 'react'
import { PriceParser } from '../../app/services/Parsers'
import Title from '../../components/Title'
import comingsoon from '../../img/comingsoon.png'

const ThemeTwoColumns = ({ title, productsToShow, columnNumber }) => (
  <div className="min-h-screen py-12 px-8">
    {title ? (
      <div className="bg-black p-4 rounded-tr-3xl rounded-bl-3xl rounded-br-3xl inline-block mb-4">
        <h4 className={`text-3xl text-white menuTitleCol${columnNumber}`}>{title}</h4>
      </div>
    ) : (
      <div className="bg-transparent p-4 mb-4">
        <h4 className={`text-3xl menuTitleCol${columnNumber}`}>&nbsp;</h4>
      </div>
    )}

    {productsToShow.map(product => (
      <div className="flex justify-start -mx-2 mb-4">
        <div className="px-2 w-1/6">
          <img src={product.image || comingsoon} alt={product.brandname} className="max-w-full" />
        </div>
        <div className="px-2 w-1/5">
          <Title
            title={product.brandname}
            subtitle={product.productName}
            titleSize="text-xs text-gray-500 productName"
            subtitleSize="title-base text-gray-800 productBrand" />
        </div>
        <div className="px-2 w-1/6">
          <Title
            title="THC"
            subtitle={`${product.thc}${product.thccbddisplay}`}
            titleSize="text-xs text-gray-500 text-center"
            subtitleSize="title-sm text-gray-800 text-center productThcCbd" />
        </div>
        <div className="px-2 w-1/6">
          <Title
            title="CBD"
            subtitle={`${product.cbd}${product.thccbddisplay}`}
            titleSize="text-xs text-gray-500 text-center"
            subtitleSize="title-sm text-gray-800 text-center productThcCbd" />
        </div>
        {product.variations.map(variation => (
          <div className="px-2 w-1/6">
            <Title
              title={variation.displayname}
              subtitle={PriceParser(variation.price)}
              titleSize="text-xs text-gray-500 text-center"
              subtitleSize="title-sm text-gray-800 text-center productPrice whitespace-no-wrap" />
          </div>
        ))}
      </div>
    ))}
  </div>
)

export default ThemeTwoColumns
