import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Login from './screens/Login'
import PublicScreens from './screens/PublicScreens'
import Discovery from './screens/Discovery'
import DiscoveryShow from './screens/DiscoveryShow'


const defaultViewportConfig = [
  'width=device-width',
  'initial-scale=1',
]

const discoveryViewportConfig = [
  'user-scalable=no',
  'initial-scale=1',
  'maximum-scale=1',
  'minimum-scale=1',
  'width=device-width',
  'height=device-height',
  'target-densitydpi=device-dpi', 'user-scalable=no',
  'initial-scale=1',
  'maximum-scale=1',
  'minimum-scale=1',
  'width=device-width',
  'height=device-height',
  'target-densitydpi=device-dpi',
]

const App = () => {
  const ViewPortListener = () => {
    const location = useLocation()

    useEffect(() => {
      const viewport = document.querySelector("[name='viewport']")
      if (/^\/discovery/.test(location?.pathname))
        viewport.content = discoveryViewportConfig.join(', ')
      else
        viewport.content = defaultViewportConfig.join(', ')
    }, [location])

    return null
  }

  return (
    <>
      <ViewPortListener />

      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/screens/:id" component={PublicScreens} />
        <Route exact path="/discovery" component={Discovery} />
        <Route exact path="/discovery/:id" component={DiscoveryShow} />
      </Switch>
    </>
  )
}

export default App
