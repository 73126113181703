import { useRef, useState } from 'react'
import WebSocket from 'isomorphic-ws'

const useWebSockets = () => {
  const [subscribed, setSubscribed] = useState(null)
  const [connected, setConnected] = useState(null)
  const [data, setData] = useState(null)
  const ws = useRef(null)

  const subscribe = (event, params = {}) => {
    const body = { action: 'subscribe', event, ...params }
    ws.current.send((JSON.stringify(body)))
    setSubscribed(true)
  }

  const onConnOpen = () => {
    if (ws.current) setConnected(true)
  }

  const onConnClose = () => {
    setConnected(true)
    setSubscribed(true)
  }

  const onMessage = messageData => {
    if (messageData) {
      setData(messageData.data)
      setTimeout(() => {
        setData(null)
      }, 100)
    }
  }

  const connect = () => {
    ws.current = new WebSocket(process.env?.REACT_APP_WEBSOCKET_URL || '')
    ws.current.onopen = onConnOpen
    ws.current.onmessage = onMessage
    ws.current.onclose = onConnClose
  }

  return [data, connect, subscribe, subscribed, connected]
}

export default useWebSockets
