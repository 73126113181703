import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import login from './reducers/LoginSlice'

import admin from './reducers/AdminUsersSlice'

import discovery from './reducers/DiscoverySlice'


const VERSION = '0.10.9'

const persistConfig = {
  key: 'waioportalroot',
  storage,
}
const discoveryConfig = {
  key: 'discovery',
  storage,
}

const authReducer = combineReducers({ login, admin })



const discoveryReducer = combineReducers({ discovery })

const reducers = combineReducers({
  discovery: persistReducer(discoveryConfig, discoveryReducer),
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
export const persistor = persistStore(store)
