import React, { useState, useEffect } from 'react'



function Terms({}) {


  return (
    <div className="w-1/1">
        <div className="h-full flex justify-center items-center py-4 px-4 pt-2">
        <div className="flex flex-col w-full h-full pb-20">
              <h1 className="text-4xl font-bold">
                Terms Of Service
              </h1>
                {/* <h1 className="text-2xl font-bold ">Privacy Policy</h1> */}
                <p>Last updated: August 19, 2021</p>
                <br></br>
                <h1 className="text-2xl font-bold">Terms and Conditions</h1>
                <p className="terms">These terms and conditions are the contract between you and WAIO Soft (“us”, “we”, etc). By visiting or using Our Website, or signing up for our Services, you agree to be bound by them.
                I/We are WAIO Soft, a company registered in Canada company number 2755572 Ontario Inc.</p>
                <p className="terms">Our address is 200-1701 Avenue Road, Toronto, ON. 
                HST Registration Number: 73446 4936 RT0001</p>
                <p className="terms">You are: Anyone who uses Our Website.</p>
                <p className="terms">Please read this agreement carefully and save it. If you do not agree with it, you should leave Our Website immediately.</p>
                <p className="terms font-bold">These are the agreed terms</p>
                <p className="terms font-bold">1.	Definitions</p>
                <p className="">
                - “Content”	means the textual, visual or aural content that is encountered as part of your experience on Our Website. It may include, among other things: text, images, sounds, videos and animations. It includes content Posted by you.
                <br></br>
                - "Intellectual Property"	means intellectual property owned by us, of every sort, whether or not registered or registrable in any country, including intellectual property of all kinds coming into existence after today; and including, among others, patents, trademarks, unregistered marks, designs, copyrights, software, domain names, discoveries, creations and inventions, together with all rights which are derived from those rights.
                <br></br>
                - “Our Website”	means any website or service designed for electronic access by mobile or fixed devices which is owned or operated by us or any member of the WAIO Soft group of companies. It includes all web pages, softwares and platforms controlled by us. 
                <br></br>
                - "Post"	means place on or into Our Website any Content or material of any sort by any means.
                <br></br>
                - “Services”	means all of the services available from Our Website, whether free or charged.
                <br></br>
                - “Visitor”	means anyone who visits Our Website.
                </p>
                <p className="terms font-bold">2.	Interpretation</p>
                <p className="">
                In this agreement, unless the context otherwise requires:
                <br></br>
                2.1.	a reference to a person includes a human individual, a corporate entity and any organisation which is managed or controlled as a unit.
                <br></br>
                2.2.	a reference to a person includes reference to that person’s successors, legal representatives, permitted assigns and any person to whom rights and obligations are transferred or passed as a result of a merger, division, reconstruction or other re-organisation involving that person.
                <br></br>
                2.3.	in the context of permission, “may not” in connection with an action of yours, means “must not”.
                <br></br>
                2.4.	the headings to the paragraphs and schedules (if any) to this agreement are inserted for convenience only and do not affect the interpretation.
                <br></br>
                2.5.	any agreement by either party not to do or omit to do something includes an obligation not to allow some other person to do or omit to do that same thing;
                <br></br>
                2.6.	any obligation of any person arising from this agreement may be performed by any other person;
                <br></br>
                2.7.	a reference to an act or regulation includes new law of substantially the same intent as the act or regulation referred to.
                <br></br>
                2.8.	these terms and conditions apply to all supplies of Services by us. They prevail over any terms proposed by you.
                <br></br>
                2.9.	this agreement is made only in the English language. If there is any conflict in meaning between the English language version of this agreement and any version or translation of this agreement in any other language, the English language version shall prevail.
                </p>
                <p className="terms font-bold">3.	Basis of Contract</p>
                <p className="">
                3.1.	In entering into this contract you have not relied on any representation or information from any source except the definition and explanation of the Services given on Our Website.
                <br></br>
                3.2.	Subject to these terms and conditions, we agree to provide to you some or all of the Services described on Our Website at a recurring subscription cost. 
                <br></br>
                3.3.	You acknowledge that you understand exactly what is included in the Services and you are satisfied that the Services are suitable and satisfactory for your requirements; 
                <br></br>
                3.4.	So far as we allow use of our Intellectual Property, we grant a licence to you, limited to the terms set out in this agreement.
                <br></br>
                3.5.	Our contract with you and licence to you last for one year from the date of start of service. Any continuation by us or by you after the expiry of one year is a new contract under the terms then posted on Our Website. Your continued use of our Services after that shall be deemed acceptance by you of the changed Service, system and/or terms.
                <br></br>
                3.6.	The contract between us comes into existence when we receive payment from you for a Service.
                <br></br>
                3.7.	We may change this agreement and/or the way we provide the Services, at any time. If we do:
                <br></br>
                3.7.1	the change will take effect when we Post it on Our Website.
                <br></br>
                3.7.2	we will give you notice of the change. If you do not accept the change, we will refund the money you have paid for the Service to date.
                <br></br>
                3.7.3	if you make any payment for Services or goods in the future, you will do so under the terms Posted on Our Website at that time.
                </p>

                <p className="terms font-bold">4.	Your account and personal information</p>
                <p className="terms">
                4.1.	When you visit Our Website, you accept responsibility for any action done by any person using your name, account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner.
                <br></br>
                4.2.	You agree that you have provided accurate, up to date, and complete information about yourself. We are not responsible for any error made as a result of such information being inaccurate.
                <br></br>
                4.3.	You agree to notify us of any changes in your information immediately it occurs. If you do not do so, we may terminate your account.
                </p>

                <p className="terms font-bold">5.	The price</p>
                5.1.	The prices payable for Services are set out on Our Website.
                <br></br>
                5.2.	The price charged for any Services may differ from one country to another. You may not be entitled to the lowest price unless you reside in the qualifying country.
                <br></br>
                5.3.	Prices are inclusive of any goods and services tax/harmonized sales tax or other sales tax.

                <p className="terms font-bold">6.	Renewal payments</p>
                6.1.	At least two weeks before expiry of the period for which you have paid, we shall send you a message to your last known email address to tell you that you licence to use the Services is shortly to expire and to invite you to renew. An invoice for the new period will be included.
                <br></br>
                6.2.	At expiry of your WAIO subscription we shall automatically take payment from your credit card of the sum specified on the invoice sent earlier and shall confirm the renewal of your WAIO subscription for a further period by sending you an email message.

                <p className="terms font-bold">7.	How we handle your Content</p>
                7.1.	Our privacy policy is strong and precise. It complies fully with Personal Information Protection and Electronic Documents Act 2000 which is at www.waiosoft.com/privacy
                <br></br>
                7.2.	If you Post Content to any public area of Our Website it becomes available in the public domains. We have no control over who sees it or what anyone does with it.
                <br></br>
                7.3.	Even if access to your text is behind a user registration it remains effectively in the public domain because someone has only to register and log in, to access it. You should therefore avoid Posting unnecessary confidential information.
                <br></br>
                7.4.	We need the freedom to be able to publicise our Services and your own use of them. You therefore now irrevocably grant us the right and licence to edit, copy, publish, distribute, translate and otherwise use any Content that you place on Our Website, in public domains and in any medium. You represent and warrant that you are authorised to grant all such rights.
                <br></br>
                7.5.	We will use that licence only for commercial purposes of the business of Our Website and will stop using it after a commercially reasonable period of time.
                <br></br>
                7.6.	You agree to waive your right to the integrity and the right to be associated with your work as provided in the Copyright Act 1985.
                <br></br>
                7.7.	You now irrevocably authorise us to publish feedback, comments and ratings about your activity through Our Website, even though it may be defamatory or critical
                <br></br>
                7.8.	Posting content of any sort does not change your ownership of the copyright in it. We have no claim over it and we will not protect your rights for you.
                <br></br>
                7.9.	You understand that you are personally responsible for your breach of someone else’s intellectual property rights, defamation, or any law, which may occur as a result of any Content having been Posted by you;
                <br></br>
                7.10.	You accept all risk and responsibility for determining whether any Content is in the public domain and not confidential.
                <br></br>
                7.11.	Please notify us of any security breach or unauthorised use of your account.

                <p className="terms font-bold">8.	Restrictions on what you may Post to Our Website</p>
                We invite you to Post Content to Our Website in several ways and for different purposes. We have to regulate your use of Our Website to protect our business and our staff, to protect other users of Our Website and to comply with the law. These provisions apply to all users of Our Website.
                <br></br>
                We do not undertake to moderate or check every item Posted, but we do protect our business vigorously. If we believe Content Posted breaches the law, we shall co-operate fully with the law enforcement authorities in whatever way we can. 
                <br></br>
                <p className="font-bold">You agree that you will not use or allow anyone else to use Our Website to Post Content or undertake any activity which is or may:</p>
                8.1.	be unlawful, or tend to incite another person to commit a crime;
                <br></br>
                8.2.	consist in commercial audio, video or music files;
                <br></br>
                8.3.	be obscene, offensive, threatening, violent, malicious or defamatory;
                <br></br>
                8.4.	be sexually explicit or pornographic;
                <br></br>
                8.5.	be likely to deceive any person or be used to impersonate any person, or to misrepresent your identity, age or affiliation with any person;
                <br></br>
                8.6.	request or collect passwords or other personal information from another user without his permission, nor Post any unnecessary personal information about yourself;
                <br></br>
                8.7.	be used to sell any goods or services or for any other commercial use not intended by us, for yourself or any other person. Examples are: sending private messages with a commercial purpose, or collecting information with the intention of passing it to a third party for his commercial use;
                <br></br>
                8.8.	include anything other than words (i.e. you will not include any symbols or photographs) except for a photograph of yourself in your profile in such place as we designate;
                <br></br>
                8.9.	facilitate the provision of unauthorised copies of another person's copyright work;
                <br></br>
                8.10.	link to any of the material specified in this paragraph;
                <br></br>
                8.11.	Post excessive or repeated off-topic messages to any forum or group;
                <br></br>
                8.12.	sending age-inappropriate communications or Content to anyone under the age 19.

                <p className="terms font-bold">9.	Your Posting: restricted content</p>
                In connection with the restrictions set out below, we may refuse or edit or remove a Posting which does not comply with these terms.
                <br></br>
                <p className="font-bold">In addition to the restrictions set out above, a Posting must not contain:</p>
                9.1.	hyperlinks, other than those specifically authorized by us;
                <br></br>
                9.2.	keywords or words repeated, which are irrelevant to the Content Posted.
                <br></br>
                9.3.	the name, logo or trademark of any organisation other than that of you or your client.
                <br></br>
                9.4.	inaccurate, false, or misleading information;

                <p className="terms font-bold">10.	Removal of offensive Content</p>
                10.1.	For the avoidance of doubt, this paragraph is addressed to any person who comes on Our Website for any purpose.
                <br></br>
                10.2.	We are under no obligation to monitor or record the activity of any customer for any purpose, nor do we assume any responsibility to monitor or police Internet-related activities. However, we may do so without notice to you and without giving you a reason.
                <br></br>
                10.3.	If you are offended by any Content, the following procedure applies:
                <br></br>
                10.3.1	your claim or complaint must be submitted to us in the form available on Our Website, or contain the same information as that requested in our form. It must be sent to us by post or email.
                <br></br>
                10.3.2	we shall remove the offending Content as soon as we are reasonably able;
                <br></br>
                10.3.3	after we receive notice of a claim or complaint, we shall investigate so far as we alone decide;
                <br></br>
                10.3.4	we may re-instate the Content about which you have complained or not.
                <br></br>
                10.4.	In respect of any complaint made by you or any person on your behalf, whether using our form of complaint or not, you now irrevocably grant to us a licence to publish the complaint and all ensuing correspondence and communication, without limit.
                <br></br>
                10.5.	You now agree that if any complaint is made by you frivolously or vexatiously you will repay us the cost of our investigation including legal fees if any.

                <p className="terms font-bold">11.	Security of Our Website</p>
                If you violate Our Website we shall take legal action against you. You now agree that you will not, and will not allow any other person to:
                <br></br>
                11.1.	modify, copy, or cause damage or unintended effect to any portion of Our Website, or any software used within it.
                <br></br>
                11.2.	link to Our Website in any way that would cause the appearance or presentation of Our Website to be different from what would be seen by a user who accessed Our Website by typing the URL into a standard browser;
                <br></br>
                11.3.	download any part of Our Website, without our express written consent;
                <br></br>
                11.4.	collect or use any product listings, descriptions, or prices;
                <br></br>
                11.5.	collect or use any information obtained from or about Our Website or the Content except as intended by this agreement;
                <br></br>
                11.6.	aggregate, copy or duplicate in any manner any of the Content or information available from Our Website, other than as permitted by this agreement or as is reasonably necessary for your use of the Services;
                <br></br>
                11.7.	share with a third party any login credentials to Our Website;
                <br></br>
                11.8.	Despite the above terms, we now grant a licence to you to:
                <br></br>
                11.8.1	create a hyperlink to Our Website for the purpose of promoting an interest common to both of us. You can do this without specific permission. This licence is conditional upon your not portraying us or any product or service in a false, misleading, derogatory, or otherwise offensive manner. You may not use any logo or other proprietary graphic or trademark of ours as part of the link without our express written consent.
                <br></br>
                11.8.2	you may copy the text of any page for your personal use in connection with the purpose of Our Website or a Service we provide.

                <p className="terms font-bold">12.	Uploading to our servers</p>
                12.1.	You must not upload to, or store on our servers any material or Content which you are not permitted by this agreement to Post to Our Website.
                <br></br>
                12.2.	You may not share, let or sub-license space on the servers. (except as an authorised re-seller).
                <br></br>
                12.3.	You may not upload to any shared server, any of the following pages, namely those:
                <br></br>
                12.3.1	pages with banners, graphics or CGI scripts running from their domain being used on other domains. (e.g., hot-linking, image-sucking, load-spreading);
                <br></br>
                12.3.2	pages with very large graphic archives or galleries;
                <br></br>
                12.3.3	pages offering download archives or large media distribution (5GB), such as .zip, .tar, .sit, .ra, .avi, .mov, .asf and .GZ;
                <br></br>
                12.3.4	pages running large or busy chat rooms;
                <br></br>
                12.3.5	pages using more than 5 % of system resources.

                <p className="terms font-bold">13.	Termination</p>
                This agreement may be terminated:
                <br></br>
                13.1.	upon either of us giving the other 30 days notice in writing addressed by post to the last known land address or by e-mail to the last known e-mail address of the other of us. For this and all purposed in connection with this agreement, our addresses are at the head of this document. If your cancellation is to be effective, you must give us full information to enable us to identify:
                <br></br>
                13.1.1	who you are and;
                <br></br>
                13.1.2	that you have the proper authority to cancel and;
                <br></br>
                13.1.3	the Services you wish to cancel.
                <br></br>
                13.2.	when we terminate it, without notice, on account of your failure to comply with these terms.
                <br></br>
                13.3.	immediately by either party if a trustee receiver administrative receiver or similar officer is appointed in respect of all or any part of the business or assets of the other party or if a petition is presented or a meeting is convened for the purpose of considering a resolution or other steps are taken for the winding up of the other party or for the making of an administration or bankruptcy order (otherwise than for the purpose of an amalgamation or reconstruction).
                <br></br>
                13.4.	Any termination of this agreement by this paragraph shall be without prejudice to any other rights or remedies to which a party may be entitled.
                <br></br>
                13.5.	Termination by either party shall have the following effects:
                <br></br>
                13.5.1	your right to use the Services immediately ceases;
                <br></br>
                13.5.2	we are under no obligation to forward any unread or unsent messages to you or any third party;
                <br></br>
                13.6.	In the event of such termination by us, we will within 14 days refund you the balance of your cost outstanding for any Service, pro rata with time not elapsed;
                <br></br>
                13.7.	There shall be no reimbursement or credit if we decide in our absolute discretion that you have failed to comply with any of the terms of this agreement. 

                <p className="terms font-bold">14.	Interruption to Services</p>
                14.1.	If it is necessary for us to interrupt the Services, we will give you reasonable notice where this is possible and when we judge the downtime is such as to justify telling you.
                <br></br>
                14.2.	You acknowledge that the Services may also be interrupted for many reasons beyond our control.
                <br></br>
                14.3.	You agree that we are not liable to you for any loss, foreseeable or not, arising from any interruption to the Services.

                <p className="terms font-bold">15.	Intellectual Property</p>
                You agree that at all times you will:
                <br></br>
                15.1.	not cause or permit anything which may damage or endanger our title to the Intellectual Property.
                <br></br>
                15.2.	notify us of any suspected infringement of the Intellectual Property;
                <br></br>
                15.3.	indemnify us for any loss or expense arising from your misuse of the Intellectual Property;
                <br></br>
                15.4.	on the expiry or termination of this agreement immediately stop using the Intellectual Property except as expressly authorised by us in writing;
                <br></br>
                15.5.	not use any name or mark similar to or capable of being confused with any name or mark of ours;
                <br></br>
                15.6.	so far as concerns software provided or made accessible by us to you, you will not:
                <br></br>
                15.6.1	copy, or make any change to any part of its code;
                <br></br>
                15.6.2	use it in any way not anticipated by this agreement;
                <br></br>
                15.6.3	give access to it to any other person than you, the licensee in this agreement;
                <br></br>
                15.6.4	in any way provide any information about it to any other person or generally.
                <br></br>
                15.7.	not use the Intellectual Property except directly in our interest.

                <p className="terms font-bold">16.	Bandwidth and data storage</p>
                16.1.	You agree that bandwidth and storage requirement shall not exceed the amount ordered by you.
                <br></br>
                16.2.	If your bandwidth and storage requirement exceeds the contractually ordered amount we may in our discretion:
                <br></br>
                16.2.1	charge the price currently charged by us for the additional usage you have used, such charges to be paid within 30 days of the invoice date or
                <br></br>
                16.2.2	if in our opinion your usage puts at risk the continued Services provision to other customers, we may limit the Services we provide to what we have agreed in our contract with you. We may not be able to give you notice of this.
                <br></br>
                16.3.	We assume no responsibility for the deletion or failure to store or deliver email or other messages.
                <br></br>
                16.4.	We may, from time to time, set a limit on the number of messages you may send, store, or receive through the Service. We may delete messages in excess of that limit. We shall give you notice of any change to your limit, except in an emergency.
                <br></br>
                16.5.	You accept that we cannot be liable to you for any such deletion or failure to deliver to you.
                <br></br>
                16.6.	We maintain reasonable procedures for general backup of data for our own purposes, but we give no warranty that your data will be saved or backed up in any particular circumstances unless we have made specific contractual arrangements with you in writing.

                <p className="terms font-bold">17.	Disclaimers and limitation of liability</p>
                17.1.	The law differs from one country to another. This paragraph applies so far as the applicable law allows.
                <br></br>
                17.2.	All implied conditions, warranties and terms are excluded from this agreement. If in any jurisdiction an implied condition, warrant or term cannot be excluded, then this subparagraph shall be deemed to be reduced in effect, only to the extent necessary to release that specific condition, warranty or term.
                <br></br>
                17.3.	The WAIO Website and WAIO Services are provided “as is”. We make no representation or warranty that the software will be:
                <br></br>
                17.3.1	useful to you;
                <br></br>
                17.3.2	of satisfactory quality;
                <br></br>
                17.3.3	fit for a particular purpose;
                <br></br>
                17.3.4	available or accessible, without interruption, or without error;
                <br></br>
                17.4.	We claim no expert knowledge in any subject. We disclaim any obligation or liability to you arising directly or indirectly from information you take from Our Website.
                <br></br>
                17.5.	We shall not be liable to you for any loss or expense arising out of or in connection with your use of Our Website, which is indirect or consequential loss, or economic loss or other loss of turnover, profits, business or goodwill. This applies whether in an action of contract, negligence or otherwise, even if such loss was reasonably foreseeable or we knew you might incur it.
                <br></br>
                17.6.	We make no representation or warranty and accept no responsibility in law for:
                <br></br>
                17.6.1	accuracy of any Content or the impression or effect it gives;
                <br></br>
                17.6.2	delivery of Content, material or any message;
                <br></br>
                17.6.3	privacy of any transmission;
                <br></br>
                17.6.4	any act or omission of any person or the identity of any person who introduces himself to you through Our Website;
                <br></br>
                17.6.5	any aspect or characteristic of any goods or services advertised on Our Website;
                <br></br>
                17.7.	Our Website includes Content Posted by third parties. We are not responsible for any such Content. If you come across any Content which offends you, please contact us via the “Contact us” page on Our Website.
                <br></br>
                17.8.	We will do all we can to maintain access to Our Website, but it may be necessary for us to suspend all or part of our service for repairs, maintenance or other good reasons. We may do so without telling you first.
                <br></br>
                17.9.	You agree that in any circumstances when we may become liable to you, the limit of our liability is the amount you have paid us in the immediately preceding 12 month period for the Services concerned.
                <br></br>
                OR
                <br></br>
                17.10.	Our total liability under this agreement, however it arises, shall not exceed the sum of $5,000. This applies whether your case is based on contract, tort or any other basis in law.
                <br></br>
                17.11.	This paragraph (and any other paragraph which excludes or restricts our liability or provides an indemnity to us) applies to our directors, officers, employees, subcontractors, agents and affiliated companies, as well as to us.
                <br></br>
                17.12.	If you become aware of any breach of any term of this agreement by any person, please tell us by [state action required]. We welcome your input [but do not guarantee to agree with your judgement].
                <br></br>
                17.13.	Nothing in this agreement excludes liability for a party's fraud.

                <p className="terms font-bold">18.	You indemnify us</p>
                You agree to indemnify us against all costs, claims and expense arising directly or indirectly from:
                <br></br>
                18.1.	your failure to comply with the law of any country;
                <br></br>
                18.2.	your breach of this agreement;
                <br></br>
                18.3.	any act, neglect or default by any agent, employee, licensee or customer of yours;
                <br></br>
                18.4.	a contractual claim arising from your use of the Services;
                <br></br>
                18.5.	a breach of the intellectual property rights of any person;
                For the purpose of this paragraph, you agree that the cost of our management and technical time is properly recoverable and can reasonably be valued at $ 150.00 per hour without further proof.

                <p className="terms font-bold">19.	Miscellaneous matters</p>
                19.1.	You undertake to provide to us your current land address, e-mail address and telephone number as often as they are changed together with all information that we may require to enable us to fulfil our obligations under this contract.
                <br></br>
                19.2.	The schedules, if any, to this agreement are part of the agreement and have the same force and effect
                <br></br>
                19.3.	If any term or provision of this agreement is at any time held by any jurisdiction to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.
                <br></br>
                19.4.	The rights and obligations of the parties set out in this agreement shall pass to any permitted successor in title.
                <br></br>
                19.5.	If you are in breach of any term of this agreement, we may:
                <br></br>
                19.5.1	publish all text and Content relating to the claimed breach, including your name and email address and all correspondence between us and our respective advisers; and you now irrevocably give your consent to such publication.
                <br></br>
                19.5.2	terminate your account and refuse access to Our Website;
                <br></br>
                19.5.3	remove or edit Content, or cancel any order at our discretion;
                <br></br>
                19.5.4	issue a claim in any court.
                <br></br>
                19.6.	Any obligation in this agreement intended to continue to have effect after termination or completion shall so continue.
                <br></br>
                19.7.	No failure or delay by any party to exercise any right, power or remedy will operate as a waiver of it nor indicate any intention to reduce that or any other right in the future.
                <br></br>
                19.8.	You agree that we may disclose your information including assigned IP numbers, account history, account use, etc. to any judicial or proper legal authority who makes a written request without further consent or notification to you.
                <br></br>
                19.9.	In the event of a dispute between the parties to this agreement, then they undertake to attempt to settle the dispute by engaging in good faith with the other in a process of mediation before commencing arbitration or litigation.
                <br></br>
                19.10.	So far as the law permits, and unless otherwise stated, this agreement does not give any right to any third party.
                <br></br>
                19.11.	Neither party shall be liable for any failure or delay in performance of this agreement which is caused by circumstances beyond its reasonable control, including any labour dispute between a party and its employees
                <br></br>
                19.12.	In the event of any conflict between any term of this agreement and the provisions of the articles of a limited company or any comparable document intended to regulate any other corporate or collective body, then the terms of this agreement shall prevail.
                <br></br>
                19.13.	The validity, construction and performance of this agreement shall be governed by the laws of the Province of Ontario and the parties agree that any dispute arising from it shall be litigated only in that Province.
                 



            
        </div>
    </div>
  </div>
  )
}

export default Terms
