import React from 'react'
import { PriceParser } from '../../app/services/Parsers'
import Title from '../../components/Title'

const ThemeThreeColumns = ({ title, productsToShow, columnNumber }) => (
  <div className="min-h-screen py-12 px-8 flex">
    <div className="w-1/4 relative">
      <h4 className={`text-6xl text-center uppercase inline-block menuTitleCol${columnNumber}`} style={{ transform: 'rotate(270deg)', marginLeft: '-70px' }}>{title}&nbsp;</h4>
    </div>

    <div className="flex-1">
      {productsToShow.map(product => (
        <div className="flex justify-between -mx-2 mb-4">
          <div className="px-2 flex-1">
          <Title
            title={product.productName}
            subtitle={product.brandname}
            titleSize="text-base productName"
            subtitleSize="text-xs productBrand" />
            <p className="text-gray-400 text-xs productThcCbd">THC {product.thc}{product.thccbddisplay} | CBD {product.cbd}{product.thccbddisplay}</p>
          </div>
          <div className="px-2 w-1/3">
            {product.variations.map(variation => (
              <p className="text-sm text-right productPrice whitespace-no-wrap">{variation.displayname} - {PriceParser(variation.price)}</p>
            ))}
          </div>
        </div>
      ))}
    </div>

  </div>
)

export default ThemeThreeColumns
