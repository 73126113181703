import { createSlice } from '@reduxjs/toolkit'

export const DiscoverySlice = createSlice({
  name: 'discovery',
  initialState: {
    location: {},
    device: {},
    products: [],
  },
  reducers: {
    setLocation: (state, { payload }) => {
      const newState = state
      newState.location = payload
    },
    setDevice: (state, { payload }) => {
      const newState = state
      newState.device = payload
    },
    setProducts: (state, { payload }) => {
      const newState = state
      newState.products = payload
    },
  },
})

export const { setLocation, setDevice, setProducts } = DiscoverySlice.actions

export default DiscoverySlice.reducer
