import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AsyncContent = ({ children, loading, error, load }) => {
  if (loading)
    return (
      <div className="flex flex-col mb-6">
        <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="bg-white align-middle min-w-full shadow sm:rounded-3xl border-b border-gray-200 flex flex-col items-center justify-center p-8">
            <FontAwesomeIcon icon="spinner" spin size="lg" />
            <h2>Loading data...</h2>
          </div>
        </div>
      </div>
    )

  if (error)
    return (
      <div className="flex flex-col mb-6">
        <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="bg-white align-middle min-w-full shadow sm:rounded-3xl border-b border-gray-200 flex flex-col items-center justify-center p-8">
            <FontAwesomeIcon icon="times" size="lg" />
            <h2>{error}</h2>

            <button type="button" className="btn btn-primary" onClick={load}>Try again</button>
          </div>
        </div>
      </div>
    )

  return (children)
}

export default AsyncContent
