import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setLocation, setDevice } from '../../app/reducers/DiscoverySlice'

function DiscoveryLocations({ company, locations }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [locationid, setlocationid] = useState(null)

  const selectDevice = (location, device) => {
    dispatch(setLocation(location))
    dispatch(setDevice(device))
    history.replace({ pathname: `/discovery/${device.deviceid}` })
  }

  if (!company) return null

  return (
    <>
      <h2 className="mt-6 text-center text-lg leading-9 text-gray-600">
        {company.companyname}
      </h2>

      <p className="mt-6 text-center text-gray-500 mb-3">
        Please select a location.
      </p>
      <div>
        {locations.map(location => (
          <>
            <button type="button" className="w-full btn btn-primary text-white flex justify-between items-center mb-2"
                    onClick={() => setlocationid(location.id)}>
              {location.storename}

              <p>
                Devices: <span>{location.devices.length}</span>
              </p>
            </button>

            {locationid === location.id && (
              <div>
                <p className="mt-6 text-center text-gray-500 mb-3">
                  Please select a device.
                </p>

                {location.devices.map(device => (
                  <button type="button" className="w-full btn btn-secondary flex justify-between items-center mb-2"
                          onClick={() => selectDevice(location, device)}>
                    {device.devicename}
                  </button>
                ))}
              </div>
            )}
          </>
        ))}
      </div>
    </>
  )
}

export default DiscoveryLocations
