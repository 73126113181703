/* eslint-disable react/no-danger */
import React from 'react'
import Slider from 'react-slick'
import { useIdleTimer } from 'react-idle-timer'
import { PriceParser } from '../../app/services/Parsers'
import Title from '../../components/Title'
import comingsoon from '../../img/comingsoon.png'

function ProductDetail({
  productname,
  description,
  brandurl,
  brandname,
  iscannabis,
  category,
  thc,
  cbd,
  images,
  thccbddisplay,
  terpenes,
  variations,
  straintype,
  onAutomaticClose,
  onRequestClose,
  product,
  loyalty,
}) {
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 40000,
    onIdle: () => {
      onRequestClose()
      onAutomaticClose()
    },
    debounce: 500,
  })

  const getProductPrice = variation => {
    if (variation.memberPrice) return variation.memberPrice

    return variation.price
  }

  const getProductOriginPrice = variation => {
    if (variation.memberPrice) return variation.price
    return variation.originalPrice
  }

  return (
    <div>
      <div className="flex justify-center -mx-2">
        <div className="px-2 w-2/5">
          {images.length > 1 ? (
            <div className="block h-full lg:rounded-lg m-1">
              <Slider dots slidesToShow={1} slidesToScroll={1}>
                {images.map(image => (
                  <div
                    className="p-2 flex justify-center items-center"
                    key={image}>
                    <img
                      alt="img"
                      src={`${image}?tr=w-450,h-auto`}
                      className="w-full" />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="block h-full lg:rounded-lg m-1">
              <img
                alt="img"
                className="w-full"
                src={`${images[0]}?tr=w-450,h-auto` || comingsoon} />
            </div>
          )}
        </div>
        <div className="px-2 flex-1">
          <div className="mt-5 lg:mt-0 leading-none flex justify-between items-center">
            <div className="flex-1">
              <span className="text-gray-500 text-sm">
                {category} / {straintype}
              </span>
              <h1 className="text-2xl font-bold">{productname}</h1>
            </div>
            <div className="">
              {brandurl ? (
                <img
                  src={`${brandurl}?tr=w=-auto,h-250`}
                  alt="brand"
                  className="block h-16 object-scale-down mr-2" />
              ) : (
                <span className="text-xl">{brandname}</span>
              )}
            </div>
          </div>

          <div className="mt-2">
            {iscannabis === 1 && (
              <>
                <span className={`badge product-badge badge-${straintype}`}>
                  {thc}
                  {thccbddisplay}
                  &nbsp;THC
                </span>
                <span
                  className={`ml-3 badge product-badge badge-${straintype}`}>
                  {cbd}
                  {thccbddisplay}
                  &nbsp;CBD
                </span>
              </>
            )}
          </div>

          {/* Force Update */}

          {description && (
            <div
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {terpenes.length > 0 && (
            <div className="my-5">
              <div className="flex flex-col">
                <Title title="Terpenes" />
                <p className="leading-tight">{terpenes.join(', ')}</p>
              </div>
            </div>
          )}

          <div className="mt-2" />
          <Title title="" />
          {loyalty && loyalty.enabled ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Quantity</th>
                  {loyalty.loyaltyNameShort ? (
                    <th className="">{loyalty.loyaltyNameShort} Price</th>
                  ) : (
                    <th className="text-center">Member Price</th>
                  )}
                  <th>Market Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {variations.map(variation => (
                  <tr>
                    <td>{variation.displayname}</td>
                    {/* <td>{PriceParser(variation.price)}</td> */}
                    <td>
                      {PriceParser(getProductPrice(variation))}
                      <span className="ml-3 badge loyalty__badge-product-sale text-xs">
                        Save $
                        {(
                          getProductOriginPrice(variation) -
                          getProductPrice(variation)
                        ).toFixed(2)}
                      </span>
                    </td>
                    <td>{PriceParser(getProductOriginPrice(variation))}</td>
                    <td>{variation.status}</td>
                    <td />
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {variations.map(variation => (
                  <tr>
                    <td>{variation.displayname}</td>
                    <td>{PriceParser(variation.price)}</td>
                    <td>{variation.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end">
        {/* <button className="btn btn-black-outline ml-3" type="button" onClick={onRequestClose}>
          Close
        </button> */}
      </div>
    </div>
  )
}

export default ProductDetail
