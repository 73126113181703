import React from 'react'

function Title(props) {
  const titleClass = props.titleSize ? props.titleSize : 'text-base'
  const subtitleClass = props.subtitleSize ? props.subtitleSize : 'text-xs'

  return (
    <div>
      <p className={`text-gray-800 leading-5 font-semibold ${titleClass}`}>
        {props.title}
      </p>
      {props.subtitle && (
        <p className={`text-gray-500 leading-5 font-semibold ${subtitleClass}`}>
          {props.subtitle}
        </p>
      )}
    </div>
  )
}

export default Title
